import React, { Fragment } from 'react'
import { useFetchAPI } from 'hooks'
import styled from 'styled-components'
import css from '@styled-system/css'
import { Box, Flex, Image, ResponsiveSVG } from 'ui/acro'
import {
  difficultyClasses,
  durationClasses,
  fitnessCategories,
  mindfulnessCategories,
  skillsCategories,
  wellnessCategories,
  yogaCategories
} from '../../common/data/ClassNavData'
import ClassResultItem from '../../Desktop/RightContent/Search/SearchDrawer/common/ClassResultItem'
import { FullDivider } from '../common/Dividers'
import MobileMenuAccordion, {
  AccordionContent,
  AccordionP
} from '../common/MobileMenuAccordion'
import { useUserContext } from 'context'
import {
  Introductory,
  Advanced,
  Beginner,
  Intermediate,
  Moderate
} from 'images'
import SeeAllCategoryLink from '../common/SeeAllCategoryLink'

const AccordionPList = styled(AccordionP)`
  ${css({
    fontFamily: 'Arquitecta, Helvetica, Arial, sans-serif',
    textAlign: 'left',
    fontSize: '18px',
    textTransform: 'uppercase'
  })}
`
const DifficultyMenuItem = styled(Flex)`
  ${css({
    gap: '10px',
    alignItems: 'center',
    pl: '1.5em'
  })}
`

const MobileMenuClasses = () => {
  const { user } = useUserContext()
  const classCategories = [
    {
      category: 'Yoga',
      data: yogaCategories.subcategories,
      classFinderLink: yogaCategories.classFinderLink
    },
    {
      category: 'Fitness',
      data: fitnessCategories.subcategories,
      classFinderLink: fitnessCategories.classFinderLink
    },
    {
      category: 'Mindfulness',
      data: mindfulnessCategories.subcategories,
      classFinderLink: mindfulnessCategories.classFinderLink
    },
    {
      category: 'Wellness',
      data: wellnessCategories.subcategories,
      classFinderLink: wellnessCategories.classFinderLink
    },
    {
      category: 'Skills',
      data: skillsCategories.subcategories,
      classFinderLink: skillsCategories.classFinderLink
    }
  ]

  const [{ data: classesVideos }] = useFetchAPI(
    '/suggest/created/categories/recent/?thumbnails=normal&limit=3'
  )
  const newClasses = classesVideos && classesVideos.content

  return (
    <Box pb='env(safe-area-inset-bottom)'>
      {classCategories.map((item, idx) => {
        return (
          <Fragment key={idx}>
            <MobileMenuAccordion title={item.category}>
              <AccordionContent>
                <>
                  {item.data.map((item, idx) => {
                    return (
                      <Flex
                        key={idx}
                        as='a'
                        href={item.url}
                        flexDirection='column'
                      >
                        <Image src={item.image} minWidth='130px' />
                        <AccordionP mt='8px'>{item.title}</AccordionP>
                      </Flex>
                    )
                  })}
                  <SeeAllCategoryLink
                    title={item.category}
                    href={item.classFinderLink}
                  />
                </>
              </AccordionContent>
            </MobileMenuAccordion>
            <FullDivider />
          </Fragment>
        )
      })}
      {/* the API we use to render new releases cards isn't available for signed out users */}
      {user && newClasses && (
        <>
          <MobileMenuAccordion title={'New Releases'}>
            <AccordionContent flexDirection='column' mb='25px'>
              <>
                {newClasses.map((item, idx) => {
                  return (
                    <ClassResultItem
                      key={idx}
                      id={'NewReleaseClasses' + (idx + 1)} // +1 so we don't start with position 0
                      textColor={'white !important'} // make sure it's white on the black BG
                      href={item.url && item.url}
                      thumbnailImage={
                        item.thumbnails.default && item.thumbnails.default
                      }
                      title={item.title && item.title}
                      humanDuration={item.duration && item.duration}
                      coaches={item.instructors && item.instructors}
                      category={
                        item.primaryCategory.name && item.primaryCategory.name
                      }
                      subcategory={
                        item.primarySubcategory.name &&
                        item.primarySubcategory.name
                      }
                    />
                  )
                })}
                <SeeAllCategoryLink
                  title={'New Releases'}
                  href={'/class-finder?order=date&'}
                />
              </>
            </AccordionContent>
          </MobileMenuAccordion>
          <FullDivider />
        </>
      )}
      <MobileMenuAccordion title={durationClasses.title}>
        <AccordionContent flexDirection='column'>
          {durationClasses.links.map((item, idx) => {
            return (
              <AccordionPList key={idx} as='a' href={item.url} pl='1.5em'>
                {item.label} min
              </AccordionPList>
            )
          })}
        </AccordionContent>
      </MobileMenuAccordion>
      <FullDivider />
      <MobileMenuAccordion title={difficultyClasses.title}>
        <AccordionContent flexDirection='column'>
          {difficultyClasses.links.map((item, idx) => {
            const icons = {
              Introductory: Introductory,
              Beginner: Beginner,
              Moderate: Moderate,
              Intermediate: Intermediate,
              Advanced: Advanced
            }
            const icon = icons[item.label] ? icons[item.label] : () => null
            return (
              <DifficultyMenuItem alignItems='center' pl='1.5em' key={idx}>
                <ResponsiveSVG
                  SVG={icon}
                  fill='white'
                  width='15px'
                  height='15px'
                />
                <AccordionPList as='a' href={item.url}>
                  {item.label}
                </AccordionPList>
              </DifficultyMenuItem>
            )
          })}
        </AccordionContent>
      </MobileMenuAccordion>
      <FullDivider />
    </Box>
  )
}

export default MobileMenuClasses
