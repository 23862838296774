import React from 'react'
import css from '@styled-system/css'
import styled from 'styled-components'
import { LI, P, UL } from 'ui/acro'
import { Tag } from 'ui/bend'
import { useNavbarContext } from 'components_v2/Header/Navbar/Menus/common/data/_store'
import DropdownMenu from 'components_v2/Header/Navbar/Menus/common/DropdownMenu'

const ShopDropdown = styled(DropdownMenu)(() =>
  css({
    top: '4.35em',
    width: 'fit-content',
    flexDirection: 'column'
  })
)
const SectionItem = styled(LI)(() =>
  css({
    padding: '10px 0',
    borderBottom: '1px solid #cccccc',
    ':last-child': {
      borderBottom: '1px solid transparent'
    },
    '&:hover': {
      textDecoration: [null, null, null, 'underline']
    }
  })
)

const ShopDropdownMenu = () => {
  const { state } = useNavbarContext()
  return (
    <ShopDropdown>
      <UL>
        {state.shopMenu.map((item, idx) => (
          <SectionItem key={idx}>
            <P
              as='a'
              whiteSpace='pre'
              className='unstyled'
              fontSize='16px'
              id={item.url}
              href={item.url}
              rel={item.external ? 'noopener noreferrer' : null}
              target={item.external ? '_blank' : null}
            >
              {item.name}
              {item.flag && (
                <Tag ml='10px' color='featured'>
                  {item.flag}
                </Tag>
              )}
            </P>
          </SectionItem>
        ))}
      </UL>
    </ShopDropdown>
  )
}

export default ShopDropdownMenu
