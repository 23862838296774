export const exploreDropdownNavItems = [
  {
    link: '/class-finder?order=date',
    id: 'nav_explore_classes',
    title: 'Classes'
  },
  {
    link: '/explore/filter?order=date',
    id: 'nav_explore_series',
    title: 'Series'
  },
  {
    link: '/membership',
    id: 'nav_explore_membership',
    title: 'Membership'
  }
]
