import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { Classes } from 'images/latest'

const ClassesIcon = ({
  color,
  width = ['24px', null, '24px'],
  height = ['24px', null, '24px']
}) => (
  <ResponsiveSVG
    width={width}
    height={height}
    className='unstyled'
    color={color}
    SVG={Classes}
  />
)

export default ClassesIcon
