// This is static data until we get an API set up to pull the top instructors (or change navbar)
export const instructors = [
  {
    name: 'Emily Sferra',
    url: 'emily',
    src: 'https://d2lesx9toesny3.cloudfront.net/normal_9692220a-356a-472b-ae7d-403907bba247.jpg'
  },
  {
    name: 'Briohny Smyth',
    url: 'briohnysmyth',
    src: 'https://d2lesx9toesny3.cloudfront.net/normal_5d0c8dd7-c676-4c8a-b331-8a3a78e00dc2.jpg'
  },
  {
    name: 'Ashley Galvin',
    url: 'ashley',
    src: 'https://d2lesx9toesny3.cloudfront.net/normal_94e6e0c2-5c15-440c-8552-6aee73e01869.jpg'
  },
  {
    name: 'Bianca Wise',
    url: 'bianca-melas',
    src: 'https://d2lesx9toesny3.cloudfront.net/normal_c99c064a-31fb-45e8-8174-cc68742122e9.jpg'
  },
  {
    name: 'Louis Chandler',
    url: 'louis-chandler',
    src: 'https://d2lesx9toesny3.cloudfront.net/normal_a67addd9-a1c5-449a-b0a4-b114b1b43262.jpg'
  },
  {
    name: 'Tawny Janae',
    url: 'tawny',
    src: 'https://d2lesx9toesny3.cloudfront.net/normal_5ebbb0f8-b97e-4683-921a-c44574c2fcbd.jpg'
  },
  {
    name: 'Christa Janine',
    url: 'christa-janine',
    src: 'https://d2lesx9toesny3.cloudfront.net/normal_e3369275-b49f-401a-bee5-a5804b867bba.jpg'
  },
  {
    name: 'Johanna Thompson',
    url: 'johanna-thompson',
    src: 'https://d2lesx9toesny3.cloudfront.net/normal_025632b3-848e-47e7-9435-bef5095c020b.jpg'
  },
  {
    name: 'Roxie Jones',
    url: 'roxie',
    src: 'https://d2lesx9toesny3.cloudfront.net/normal_b61fae0c-a200-47e1-a291-5eee10cc2bbc.jpg'
  },
  {
    name: 'Jackie Stewart',
    url: 'jackie',
    src: 'https://d2lesx9toesny3.cloudfront.net/normal_e8c07a40-4d1c-4080-8158-79841056efed.jpg'
  },
  {
    name: 'Calvin Corzine',
    url: 'calvin',
    src: 'https://d2lesx9toesny3.cloudfront.net/normal_811c1c36-6ea5-4f59-8741-3e2fdf94cad9.jpg'
  },
  {
    name: 'Kirat Randhawa',
    url: 'kirat',
    src: 'https://d2lesx9toesny3.cloudfront.net/normal_15a816d1-a508-490a-87b0-b1bcd9b2355a.jpg'
  },
  {
    name: 'Josh Kramer',
    url: 'josh',
    src: 'https://d2lesx9toesny3.cloudfront.net/normal_ee89bcd5-f820-4494-a8f3-7df972033d7c.jpg'
  },
  {
    name: 'Dr. Amy Shah',
    url: 'amy-shah',
    src: 'https://d2lesx9toesny3.cloudfront.net/normal_f08fd2a9-6670-47d8-8706-c87ebfc91229.jpg'
  },
  {
    name: 'Jacy Cunningham',
    url: 'jacy',
    src: 'https://d2lesx9toesny3.cloudfront.net/normal_75429235-e41c-40dc-8813-ae3f8c3a5ea0.jpg'
  }
]
