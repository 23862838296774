import React from 'react'
import css from '@styled-system/css'
import { SeriesResultItem } from 'components_v2/Header/Navbar/Menus/Desktop/RightContent/Search/SearchDrawer/common'
import { useFetchAPI } from 'hooks'
import styled from 'styled-components'
import { Grid, LI, P, UL } from 'ui/acro'
import {
  fitnessCategories,
  mindfulnessCategories,
  skillsCategories,
  wellnessCategories,
  yogaCategories
} from '../../../../common/data/SeriesNavData'
import DropdownMenu from 'components_v2/Header/Navbar/Menus/common/DropdownMenu'
import SeriesInfoBanner from 'components_v2/Header/Navbar/Menus/common/SeriesInfoBanner'

const SeriesDropdown = styled(DropdownMenu)(() =>
  css({
    top: '4.35em',
    left: '0',
    width: '100%',
    // overwrite dropdown menu padding and add gap to accommodate series info banner
    '.menu-container': {
      padding: '0',
      gap: '10px'
    }
  })
)
const SeriesGrid = styled(Grid)(() =>
  css({
    maxWidth: '1200px',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridColumnGap: '3em',
    padding: '20px 30px 40px 30px',
    '.long-grid-item': {
      gridColumn: '5 /-1',
      gap: '10px'
    }
  })
)
const FlexBox = styled(UL)(() =>
  css({
    display: 'flex',
    flexDirection: 'column',
    gap: '5px'
  })
)
const SectionTitle = styled(P)(() =>
  css({
    fontFamily: 'Arquitecta, Helvetica, Arial, sans-serif',
    fontSize: '16px',
    fontWeight: 500,
    textTransform: 'uppercase',
    letterSpacing: '.05em',
    borderBottom: '1px solid #E1E1E1',
    mb: '10px',
    pb: '3px',
    '&:hover': {
      textDecoration: [null, null, null, 'underline']
    }
  })
)
const SectionItem = styled(P)(() =>
  css({
    fontSize: '16px',
    letterSpacing: '.05em',
    '&:hover': {
      textDecoration: [null, null, null, 'underline']
    }
  })
)

const SeriesDropdownMenu = () => {
  const [{ data: seriesVideos }] = useFetchAPI('/newest_plans.json?limit=2')
  const newSeries = seriesVideos && seriesVideos.content
  return (
    <SeriesDropdown width='100%'>
      <SeriesInfoBanner />
      <SeriesGrid>
        <FlexBox>
          <SectionTitle
            id={`DesktopSeriesMenu${yogaCategories.category}`}
            as='a'
            href={yogaCategories.seriesExploreLink}
            className='unstyled'
          >
            {yogaCategories.category}
          </SectionTitle>
          {yogaCategories.subcategories.map((item, idx) => {
            return (
              <LI
                id={`DesktopSeriesMenu${item.title.replace(/ /g, '')}`}
                key={idx}
              >
                <SectionItem as='a' className='unstyled' href={item.url}>
                  {item.title}
                </SectionItem>
              </LI>
            )
          })}
        </FlexBox>
        <FlexBox>
          <SectionTitle
            id={`DesktopSeriesMenu${fitnessCategories.category}`}
            as='a'
            href={fitnessCategories.seriesExploreLink}
            className='unstyled'
          >
            {fitnessCategories.category}
          </SectionTitle>
          {fitnessCategories.subcategories.map((item, idx) => {
            return (
              <LI
                id={`DesktopSeriesMenu${item.title.replace(/ /g, '')}`}
                key={idx}
              >
                <SectionItem as='a' className='unstyled' href={item.url}>
                  {item.title}
                </SectionItem>
              </LI>
            )
          })}
        </FlexBox>
        <FlexBox>
          <SectionTitle
            id={`DesktopSeriesMenu${mindfulnessCategories.category}`}
            as='a'
            href={mindfulnessCategories.seriesExploreLink}
            className='unstyled'
          >
            {mindfulnessCategories.category}
          </SectionTitle>
          {mindfulnessCategories.subcategories.map((item, idx) => {
            return (
              <LI
                id={`DesktopSeriesMenu${item.title.replace(/ /g, '')}`}
                key={idx}
              >
                <SectionItem as='a' className='unstyled' href={item.url}>
                  {item.title}
                </SectionItem>
              </LI>
            )
          })}
        </FlexBox>
        <FlexBox>
          <SectionTitle
            id={`DesktopSeriesMenu${wellnessCategories.category}`}
            as='a'
            href={wellnessCategories.seriesExploreLink}
            className='unstyled'
          >
            {wellnessCategories.category}
          </SectionTitle>
          {wellnessCategories.subcategories.map((item, idx) => {
            return (
              <LI
                id={`DesktopSeriesMenu${item.title.replace(/ /g, '')}`}
                key={idx}
              >
                <SectionItem as='a' className='unstyled' href={item.url}>
                  {item.title}
                </SectionItem>
              </LI>
            )
          })}
        </FlexBox>
        <FlexBox>
          <SectionTitle
            id={`DesktopSeriesMenu${skillsCategories.category}`}
            as='a'
            href={skillsCategories.seriesExploreLink}
            className='unstyled'
          >
            {skillsCategories.category}
          </SectionTitle>
          {skillsCategories.subcategories.map((item, idx) => {
            return (
              <LI
                id={`DesktopSeriesMenu${item.title.replace(/ /g, '')}`}
                key={idx}
              >
                <SectionItem as='a' className='unstyled' href={item.url}>
                  {item.title}
                </SectionItem>
              </LI>
            )
          })}
        </FlexBox>
        {newSeries && newSeries.length > 0 && (
          <FlexBox className='long-grid-item'>
            <SectionTitle
              id={'DesktopSeriesMenuNewReleases'}
              as='a'
              href={'/explore/filter?order=date'}
              className='unstyled'
            >
              New Releases
            </SectionTitle>
            {newSeries.map((item, idx) => {
              return (
                <LI id={'DesktopSeriesNewRelease' + (idx + 1)} key={idx}>
                  <SeriesResultItem
                    href={item.url && item.url}
                    thumbnailImage={
                      item.thumbnails.default && item.thumbnails.default
                    }
                    classesCount={item.classCount && item.classCount}
                    title={item.title && item.title}
                    coaches={item.instructors && item.instructors}
                    category={
                      item.primaryCategory.name && item.primaryCategory.name
                    }
                    subcategory={
                      item.primarySubcategory.name &&
                      item.primarySubcategory.name
                    }
                  />
                </LI>
              )
            })}
          </FlexBox>
        )}
      </SeriesGrid>
    </SeriesDropdown>
  )
}

export default SeriesDropdownMenu
