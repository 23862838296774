import React from 'react'
import css from '@styled-system/css'
import { CircleImage } from 'components_v2/Header/Navbar/Menus/Desktop/RightContent/Search/SearchDrawer/common'
import styled from 'styled-components'
import { Flex, Grid, P } from 'ui/acro'
import { instructors } from '../../../../common/data/InstructorNavData'
import DropdownMenu from 'components_v2/Header/Navbar/Menus/common/DropdownMenu'

const InstructorDropdown = styled(DropdownMenu)(() =>
  css({
    top: '4.35em',
    width: 'fit-content'
  })
)
const InstructorGrid = styled(Grid)(() =>
  css({
    gridTemplateColumns: 'repeat(5, 1fr)',
    gridRowGap: '0.5em',
    gridColumnGap: '0.25em'
  })
)
const FlexBox = styled(Flex)(() =>
  css({
    flexDirection: 'column',
    gap: '5px',
    alignItems: 'center',
    justifyContent: 'center'
  })
)
const TitleContainer = styled(Flex)(() =>
  css({
    gridColumn: '1 /-1',
    mb: '10px',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E1E1E1'
  })
)
const SectionTitle = styled(P)(() =>
  css({
    fontFamily: 'Arquitecta, Helvetica, Arial, sans-serif',
    fontSize: '16px',
    fontWeight: 500,
    textTransform: 'uppercase',
    letterSpacing: '.05em',
    margin: '0',
    pb: '3px'
  })
)
const SectionItem = styled(P)(() =>
  css({
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: 'normal',
    margin: '0',
    '&:hover': {
      textDecoration: [null, null, null, 'underline']
    }
  })
)

const InstructorDropdownMenu = () => {
  return (
    <InstructorDropdown>
      <InstructorGrid>
        <TitleContainer>
          <SectionTitle>Popular Instructors</SectionTitle>
          <P
            as='a'
            className='unstyled'
            href='/explore/instructors'
            fontSize='15px'
          >
            See All
          </P>
        </TitleContainer>
        {instructors.map((item, idx) => {
          return (
            <FlexBox
              key={idx}
              as='a'
              className='unstyled'
              href={`/instructors/${item.url}`}
            >
              <CircleImage src={item.src} />
              <SectionItem>{item.name}</SectionItem>
            </FlexBox>
          )
        })}
      </InstructorGrid>
    </InstructorDropdown>
  )
}

export default InstructorDropdownMenu
