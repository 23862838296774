import React, { useState } from 'react'
import styled from 'styled-components'

import { Box } from 'ui/acro'

import { useNavbarContext } from '../../../common/data/_store'

import NotificationBadge from './NotificationBadge'
import ProfileMenu from './ProfileMenu'
import { ProfileIcon } from '../../../../../../Icons'
import DropdownMenu from '../../../common/DropdownMenu'

const Container = styled(Box)((props) => ({
  marginBottom: '-15px',
  paddingBottom: '15px',
  '> svg': {
    stroke: props.theme.colors.white
  }
}))

const Profile = ({ color = 'white' }) => {
  const { state } = useNavbarContext()
  const [isHovered, setHovered] = useState(false)

  return (
    // TODO: make this accessible via keyboard navigation
    // (Currently only works through mouse navigation)
    <div style={{ position: 'relative' }}>
      <Container
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        aria-label='Profile Menu'
        aria-haspopup='true'
        aria-expanded={isHovered}
        color={color}
      >
        <ProfileIcon
          color={color}
          width='22px'
          height='22px'
          cursor='pointer'
        />
        <DropdownMenu isHovered={isHovered} mt='12px' right='0'>
          <ProfileMenu />
        </DropdownMenu>
      </Container>
      {state.notificationCount > 0 && (
        <NotificationBadge position='absolute' top='-6px' right='-9px' />
      )}
    </div>
  )
}

export default Profile
