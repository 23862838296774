import React from 'react'
import css from '@styled-system/css'
import styled from 'styled-components'
import { LI, P, UL } from 'ui/acro'
import { exploreDropdownNavItems } from '../../../../common/data/exploreDropdownNavItems'
import DropdownMenu from 'components_v2/Header/Navbar/Menus/common/DropdownMenu'

const ExploreDropdown = styled(DropdownMenu)(() =>
  css({
    display: 'flex',
    top: '4.35em',
    width: 'fit-content',
    flexDirection: 'column'
  })
)
const SectionItem = styled(LI)(() =>
  css({
    padding: '10px 0',
    borderBottom: '1px solid #cccccc',
    ':last-child': {
      borderBottom: '1px solid transparent'
    },
    '&:hover': {
      textDecoration: [null, null, null, 'underline']
    }
  })
)

const ExploreDropdownMenu = () => {
  return (
    <ExploreDropdown>
      <UL>
        {exploreDropdownNavItems.map((item, idx) => {
          return (
            <SectionItem key={idx}>
              <P
                as='a'
                className='unstyled'
                fontSize='16px'
                href={item.link}
                id={item.id}
              >
                {item.title}
              </P>
            </SectionItem>
          )
        })}
      </UL>
    </ExploreDropdown>
  )
}

export default ExploreDropdownMenu
