import React from 'react'
import PropTypes from 'prop-types'
import { Flex, P } from 'ui/acro'
import { ChevronDownIcon } from 'components_v2/Icons'
import styled from 'styled-components'
import css from '@styled-system/css'

const AccordionDetails = styled('details')`
  ${css({
    display: 'flex',
    flexDirection: 'column',
    px: '10px',
    '&[open]': {
      '.chevron-down-icon': {
        svg: {
          transform: 'rotate(180deg)' //rotate chevron when details box is open
        }
      }
    }
  })}
`
const AccordionSummary = styled('summary')`
  ${css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    listStyle: 'none',
    '::-webkit-details-marker, ::marker': {
      display: 'none'
    }
  })}
`
export const AccordionMenuLink = styled(P)`
  ${css({
    textTransform: 'uppercase',
    fontFamily: 'Arquitecta, Helvetica, Arial, sans-serif',
    letterSpacing: '.08em',
    fontSize: '18px',
    pt: '10px',
    pb: '10px',
    px: '4px', // Inset
    color: 'white',
    fontWeight: '400'
  })}
`
export const AccordionContent = styled(Flex)`
  ${css({
    overflow: 'auto',
    gap: '20px',
    '::-webkit-scrollbar': {
      display: 'none'
    }
  })}
`
export const AccordionP = styled(P)`
  ${css({
    color: 'white',
    lineHeight: 'normal',
    textAlign: 'center',
    letterSpacing: '.08em'
  })}
`

const MobileMenuAccordion = ({ title, children }) => {
  const details = document.querySelectorAll('details')
  details.forEach((targetDetail) => {
    targetDetail.addEventListener('click', () => {
      details.forEach((detail) => {
        if (detail !== targetDetail) {
          detail.removeAttribute('open')
        }
      })
    })
  })
  return (
    <AccordionDetails>
      <AccordionSummary>
        <P
          variant='medium'
          textTransform='uppercase'
          fontFamily='Arquitecta, Helvetica, Arial, sans-serif'
          letterSpacing='.08em'
          fontSize='18px'
          pt='10px'
          pb='10px'
          px='4px' // Inset
          color='white'
          fontWeight='400'
        >
          {title}
        </P>
        <ChevronDownIcon color='white' />
      </AccordionSummary>
      {children}
    </AccordionDetails>
  )
}

MobileMenuAccordion.propTypes = {
  title: PropTypes.string.isRequired
}

export default MobileMenuAccordion
