import css from '@styled-system/css'
import {
  ClassesIcon,
  ExploreIcon,
  InstructorsIcon,
  SeriesIcon,
  ShopIcon
} from 'components_v2/Icons'
import { useUserContext } from 'context'
import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex, Grid, P } from 'ui/acro'
import ClassDropdownMenu from './SignedIn/Classes/ClassDropdownMenu'
import InstructorDropdownMenu from './SignedIn/Instructors/InstructorsDropdownMenu'
import SeriesDropdownMenu from './SignedIn/Series/SeriesDropdownMenu'
import ExploreDropdownMenu from './SignedOut/Explore/ExploreDropdownMenu'
import ShopDropdownMenu from './SignedOut/Shop/ShopDropdownMenu'

const NavGrid = styled(Grid)(() =>
  css({
    gridTemplateColumns: 'repeat(3, 1fr)',
    columnGap: '5px'
  })
)
const NavItemFlex = styled(Flex)`
  /* need to use this css format for psuedo elements */
  flex-direction: column;
  align-items: center;
  gap: 5px;
  padding: 5px 0px;
  margin: 0px 10px;
  &:before,
  &:after {
    content: '';
    z-index: 10;
    position: absolute;
    bottom: 8px;
    height: 3px;
  }
  &:before {
    width: 55px;
  }
  &:after {
    width: 0;
    transition: width 0.25s ease-in-out;
    background: currentColor;
  }
  &:hover {
    &:after {
      width: 55px;
    }
  }
`

const NavTitle = styled(P)(() =>
  css({
    fontSize: '15px',
    lineHeight: 'normal',
    margin: '0'
  })
)

const DesktopMiddleNavContent = () => {
  const { user } = useUserContext()
  const [classHovered, setClassHovered] = useState(false)
  const [seriesHovered, setSeriesHovered] = useState(false)
  const [instructorHovered, setInstructorHovered] = useState(false)
  const [exploreHovered, setExploreHovered] = useState(false)
  const [shopHovered, setShopHovered] = useState(false)
  const [exploreInstructorHovered, setExploreInstructorHovered] =
    useState(false)
  return (
    <NavGrid>
      {user ? (
        <>
          <NavItemFlex
            onMouseEnter={() => setClassHovered(true)}
            onMouseLeave={() => setClassHovered(false)}
          >
            <ClassesIcon color='black' />
            <NavTitle>Classes</NavTitle>
            {classHovered && <ClassDropdownMenu />}
          </NavItemFlex>
          <NavItemFlex
            onMouseEnter={() => setSeriesHovered(true)}
            onMouseLeave={() => setSeriesHovered(false)}
          >
            <SeriesIcon color='black' />
            <NavTitle>Series</NavTitle>
            {seriesHovered && <SeriesDropdownMenu />}
          </NavItemFlex>
          <NavItemFlex
            onMouseEnter={() => setInstructorHovered(true)}
            onMouseLeave={() => setInstructorHovered(false)}
          >
            <InstructorsIcon color='black' />
            <NavTitle>Instructors</NavTitle>
            {instructorHovered && <InstructorDropdownMenu />}
          </NavItemFlex>
        </>
      ) : (
        <>
          <NavItemFlex
            onMouseEnter={() => setExploreHovered(true)}
            onMouseLeave={() => setExploreHovered(false)}
          >
            <ExploreIcon color='black' height='24px' />
            <NavTitle>Explore</NavTitle>
            {exploreHovered && <ExploreDropdownMenu />}
          </NavItemFlex>
          <NavItemFlex
            onMouseEnter={() => setShopHovered(true)}
            onMouseLeave={() => setShopHovered(false)}
          >
            <ShopIcon color='black' height='24px' />
            <NavTitle>Shop</NavTitle>
            {shopHovered && <ShopDropdownMenu />}
          </NavItemFlex>
          <NavItemFlex
            onMouseEnter={() => setExploreInstructorHovered(true)}
            onMouseLeave={() => setExploreInstructorHovered(false)}
          >
            <InstructorsIcon color='black' />
            <NavTitle>Instructors</NavTitle>
            {exploreInstructorHovered && <InstructorDropdownMenu />}
          </NavItemFlex>
        </>
      )}
    </NavGrid>
  )
}

export default DesktopMiddleNavContent
