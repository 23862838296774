import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { ChevronRight } from 'images/latest'

const ChevronRightIcon = ({ color = 'white' }) => (
  <ResponsiveSVG
    width={['7px', '7px', '7px']}
    height='13px'
    className='unstyled chevron-right-icon'
    fill={color}
    SVG={ChevronRight}
  />
)

export default ChevronRightIcon
