import React, { Fragment } from 'react'
import NotificationBadge from '../../Desktop/RightContent/Profile/NotificationBadge'
import MobileMenuLink from '../common/MobileMenuLink'
import { useNavbarContext } from '../../common/data/_store'
import { useUserContext } from 'context'
import { Tag } from 'ui/bend'
import { HalfDivider } from '../common/Dividers'
import { Flex } from 'ui/acro'
import styled from 'styled-components'
import css from '@styled-system/css'
import { gift_card_interest_event } from 'gtm/pushEvents'

export const MobileMenuPrimaryPage = styled(Flex)`
  ${css({
    flexDirection: 'column',
    pb: 'calc(15px + env(safe-area-inset-bottom))'
  })}
`

const MobileMenuPrimary = (props) => {
  const { user } = useUserContext()
  const { state, actions } = useNavbarContext()
  const handleMenuClick = (item) => {
    item?.name?.toLowerCase() === 'gift cards' &&
      gift_card_interest_event(user?.firstName ? 'si_nav' : 'so_nav')
  }

  return (
    <MobileMenuPrimaryPage>
      {user ? (
        <Fragment>
          <MobileMenuLink
            id='nav_classes_mobile'
            aria-label='Classes'
            onClick={() => props.pageSelected('Classes')}
          >
            Classes
          </MobileMenuLink>
          <MobileMenuLink
            id='nav_series_mobile'
            aria-label='Series'
            onClick={() => props.pageSelected('Series')}
          >
            Series
          </MobileMenuLink>
          <MobileMenuLink
            href='/explore/instructors'
            aria-label='Instructors'
            id='nav_instructors_mobile'
          >
            Instructors
          </MobileMenuLink>
          <HalfDivider />
          <MobileMenuLink
            href='/my/practice'
            id='nav_practice_mobile'
            aria-label='My Practice'
          >
            My Practice
          </MobileMenuLink>
          <MobileMenuLink
            href={`/users/${user.username}`}
            aria-label='Profile Menu'
            id='nav_profile_mobile'
          >
            My Profile
          </MobileMenuLink>
          <MobileMenuLink
            href='/user/playlists'
            id='nav_playlists_mobile'
            aria-label='My Playlists'
          >
            My Playlists
          </MobileMenuLink>
          <MobileMenuLink
            href='/notifications'
            onClick={actions.clearNotifications}
            id='nav_notifications_mobile'
            position='relative'
          >
            Notifications
            {state.notificationCount > 0 && <NotificationBadge ml='10px' />}
          </MobileMenuLink>
          <MobileMenuLink href='/community' id='nav_community_mobile'>
            Community
          </MobileMenuLink>
          <HalfDivider />
          <MobileMenuLink
            href='/alo_shop_si_web'
            id='alo_shop_si_web'
            className='unstyled'
            rel='noopener noreferrer'
            target='_blank'
          >
            Shop
          </MobileMenuLink>
          {state.shopMenu.map((item, index) => (
            <MobileMenuLink
              key={index}
              id={item.url}
              href={item.url}
              style={{ paddingLeft: '1.5em' }}
              rel={item.external ? 'noopener noreferrer' : null}
              target={item.external ? '_blank' : null}
              onClick={() => handleMenuClick(item)}
            >
              {item.name}
              {item.flag && (
                <Tag ml='10px' color='featured'>
                  {item.flag}
                </Tag>
              )}
            </MobileMenuLink>
          ))}
          <HalfDivider />
          <MobileMenuLink
            href='/settings/profile'
            id='nav_settings_mobile'
            aria-label='Settings'
          >
            Settings
          </MobileMenuLink>
          <MobileMenuLink
            href='/referrals?traffic_source=site_menu_mobile'
            id='nav_share_mobile'
            className='unstyled'
          >
            Refer A Friend
          </MobileMenuLink>
          <MobileMenuLink
            href='/support'
            id='nav_gethelp_mobile'
            className='unstyled'
          >
            Get Help
          </MobileMenuLink>
          <MobileMenuLink
            href='/signout'
            id='nav_logout_mobile'
            className='unstyled'
          >
            Log Out
          </MobileMenuLink>
        </Fragment>
      ) : (
        <Fragment>
          <MobileMenuLink href='/explore' id='nav_explore_mobile'>
            Explore
          </MobileMenuLink>
          <MobileMenuLink
            id={'nav_explore_classes'}
            aria-label='Classes'
            style={{ paddingLeft: '1.5em' }}
            onClick={() => props.pageSelected('Classes')}
          >
            Classes
          </MobileMenuLink>
          <MobileMenuLink
            id={'nav_explore_series'}
            aria-label='Series'
            style={{ paddingLeft: '1.5em' }}
            onClick={() => props.pageSelected('Series')}
          >
            Series
          </MobileMenuLink>
          <MobileMenuLink
            href={'/membership'}
            aria-label='Membership'
            id={'nav_explore_membership'}
            style={{ paddingLeft: '1.5em' }}
          >
            Membership
          </MobileMenuLink>
          <HalfDivider />
          <MobileMenuLink
            href='/alo_shop_so_web'
            id='alo_shop_so_web'
            className='unstyled'
            rel='noopener noreferrer'
            target='_blank'
          >
            Shop
          </MobileMenuLink>
          {state.shopMenu.map((item, idx) => (
            <MobileMenuLink
              key={idx}
              id={item.url}
              href={item.url}
              rel={item.external ? 'noopener noreferrer' : null}
              target={item.external ? '_blank' : null}
              style={{ paddingLeft: '1.5em' }}
              onClick={() => handleMenuClick(item)}
            >
              {item.name}
              {item.flag && (
                <Tag ml='10px' color='featured'>
                  {item.flag}
                </Tag>
              )}
            </MobileMenuLink>
          ))}
          <HalfDivider />
          <MobileMenuLink href='/signin' id='nav_signin_mobile'>
            Sign In
          </MobileMenuLink>
          <MobileMenuLink href='/surveys/onboarding' id='nav_subscribe_mobile'>
            Try For Free
          </MobileMenuLink>
        </Fragment>
      )}
    </MobileMenuPrimaryPage>
  )
}

export default MobileMenuPrimary
