import styled from 'styled-components'
import { css } from '@styled-system/css'
import Text from 'ui/acro/typography/Text'

const AltHeaderText = styled(Text)(() =>
  css({
    fontWeight: 600,
    fontSize: ['21px', '21px', '29px'],
    whiteSpace: 'noWrap',
    fontFamily: 'Proxima-nova',
    lineHeight: ['28.35px', '37.7px']
  })
)
export default AltHeaderText
