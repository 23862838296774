import styled from 'styled-components'
import { css } from '@styled-system/css'
import Text from 'ui/acro/typography/Text'

const HeaderText = styled(Text)(() =>
  css({
    fontWeight: 900,
    fontSize: ['24px', '30px', '37px'],
    textTransform: 'uppercase',
    fontFamily: 'Arquitecta',
    lineHeight: '26px',
    letterSpacing: '0.07em'
  })
)
export default HeaderText
