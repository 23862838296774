/***********************
 * Although <Alert/> is technically a block, I have collocated it with useAlert and useToast, as they are tightly coupled.
 ***********************/

import React from 'react'
import PropTypes from 'prop-types'
import styled, { useTheme } from 'styled-components'

import { ErrorBoundary } from 'helpers'
import { CTA, P } from 'ui/bend/typography'
import { Flex, GridItem, SVGImage } from 'ui/bend/elements'
import { Chevron } from 'ui/bend/animations'
import { Check, Warning, X } from 'images/bend'

/**
 * The styling for the Alert's parent container is in ui/bend/blocks/ToastContainer.js This includes background and padding.
 */

const StyledClose = styled('button')((props) => ({
  cursor: 'pointer',
  width: '24px',
  height: '24px',
  padding: '6px',
  borderRadius: '50%',
  border: 'none',
  backgroundColor: props.theme.colors.grey6
}))

const Alert = ({ alert, closeToast, cta, error, message }) => {
  const { colors } = useTheme()
  const width = alert ? ['100%', '476px'] : ['100%', '296px']
  if (cta && cta.text && cta.text.length > 25)
    console.error('CTA text is too long for this alert. It will look horrid.')

  return (
    <Flex flexDirection='row' alignItems='flex-start' width={width}>
      {error ? (
        <SVGImage
          SVG={Warning}
          width='20px'
          height='20px'
          mr='12px'
          fill={colors.base}
        />
      ) : (
        <SVGImage
          SVG={Check}
          width='14px'
          height='12px'
          mr='12px'
          pt='4px'
          fill={colors.base}
        />
      )}
      <GridItem
        flexWrap='wrap'
        flex='1'
        display='grid'
        gridTemplateColumns={['1fr', '1fr auto']}
        gridGap={'14px'}
        mr={alert ? '48px' : ''}
      >
        <P as='div' color={colors.base}>
          {message}
        </P>
        {cta && cta.link && cta.text && (
          <CTA
            as='a'
            href={cta.link}
            className='unstyled'
            alignItems='center'
            variant='small'
            style={{ color: colors.base }}
            display='flex'
            maxWidth={[null, '124px']}
          >
            {cta.text} <Chevron ml='13px' direction='right' invertColor />
          </CTA>
        )}
      </GridItem>
      {alert && (
        <StyledClose
          onClick={closeToast}
          ml='12px'
          mt='8px'
          aria-label='Close Alert'
        >
          <SVGImage SVG={X} fill={colors.base} height='100%' />
        </StyledClose>
      )}
    </Flex>
  )
}

const SafeAlert = (props) => (
  <ErrorBoundary>
    <Alert {...props} />
  </ErrorBoundary>
)

SafeAlert.propTypes = {
  alert: PropTypes.bool,
  cta: PropTypes.shape({
    link: PropTypes.string,
    text: PropTypes.string
  }),
  error: PropTypes.bool,
  message: PropTypes.string.isRequired
}

export default SafeAlert
