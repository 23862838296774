export const yogaCategories = {
  category: 'Yoga',
  seriesExploreLink: '/explore/filter?categories=yoga&order=date',
  subcategories: [
    {
      title: 'Vinyasa',
      url: '/explore/filter?categories=yoga_vinyasa&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Vinyasa.jpg'
    },
    {
      title: 'Hatha',
      url: '/explore/filter?categories=yoga_hatha&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Hatha.jpg'
    },
    {
      title: 'Restorative',
      url: '/explore/filter?categories=yoga_restorative&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Restorative.jpg'
    },

    {
      title: 'Ashtanga',
      url: '/explore/filter?categories=yoga_ashtanga&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Ashtanga.jpg'
    },
    {
      title: 'Kundalini',
      url: '/explore/filter?categories=yoga_kundalini&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Kundalini.jpg'
    },
    {
      title: 'Prenatal/Postnatal',
      url: '/explore/filter?categories=yoga_prenatal-postnatal&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Prenatal.jpg'
    },
    {
      title: 'Kids Yoga',
      url: '/explore/filter?categories=yoga_kids-yoga&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Kids-Yoga.jpg'
    }
  ]
}

export const fitnessCategories = {
  category: 'Fitness',
  seriesExploreLink: '/explore/filter?categories=fitness&order=date',
  subcategories: [
    {
      title: 'Pilates',
      url: '/explore/filter?categories=fitness_pilates&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Pilates.jpg'
    },
    {
      title: 'Barre',
      url: '/explore/filter?categories=fitness_barre&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Barre.jpg'
    },
    {
      title: 'Strength',
      url: '/explore/filter?categories=fitness_strength&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Strength.jpg'
    },

    {
      title: 'Core',
      url: '/explore/filter?categories=fitness_core&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Core.jpg'
    },

    {
      title: 'Sculpt',
      url: '/explore/filter?categories=fitness_sculpt&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Sculpt.jpg'
    },
    {
      title: 'HIIT',
      url: '/explore/filter?categories=fitness_hiit&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_HIIT.jpg'
    },
    {
      title: 'Stretching',
      url: '/explore/filter?categories=fitness_stretching&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Stretching.jpg'
    },
    {
      title: 'Dance',
      url: '/explore/filter?categories=fitness_dance&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Dance.jpg'
    },
    {
      title: 'Running',
      url: '/explore/filter?categories=fitness_running&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Running.jpg'
    },
    {
      title: 'Walking',
      url: '/explore/filter?categories=fitness_walking&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Walking.jpg'
    },
    {
      title: 'Gym',
      url: '/explore/filter?categories=fitness_gym&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Gym.jpg'
    },
    {
      title: 'Prenatal/Postnatal',
      url: '/explore/filter?categories=fitness_prenatal-postnatal&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Prenatal.jpg'
    }
  ]
}

export const mindfulnessCategories = {
  category: 'Mindfulness',
  seriesExploreLink: '/explore/filter?categories=mindfulness&order=date',
  subcategories: [
    {
      title: 'Meditation',
      url: '/explore/filter?categories=mindfulness_meditation&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/mindfulness/HP-Img-Update_Mindfulness_Meditation.jpg'
    },
    {
      title: 'Sound',
      url: '/explore/filter?categories=mindfulness_sound&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/mindfulness/HP-Img-Update_Mindfulness_Sound.jpg'
    },
    {
      title: 'Breathwork',
      url: '/explore/filter?categories=mindfulness_breathwork&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/mindfulness/HP-Img-Update_Mindfulness_Breathwork.jpg'
    },
    {
      title: 'Yoga Nidra',
      url: '/explore/filter?categories=mindfulness_yoga-nidra&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/mindfulness/HP-Img-Update_Mindfulness_Yoga+Nidra.jpg'
    },
    {
      title: 'Reiki',
      url: '/explore/filter?categories=mindfulness_reiki&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/mindfulness/HP-Img-Update_Mindfulness_Reiki.jpg'
    }
  ]
}

export const wellnessCategories = {
  category: 'Wellness',
  seriesExploreLink: '/explore/filter?categories=wellness&order=date',
  subcategories: [
    {
      title: 'Sleep',
      url: '/explore/filter?categories=wellness_sleep&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/wellness/Sleep.jpg'
    },
    {
      title: 'Self-Care',
      url: '/explore/filter?categories=wellness_self-care&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/wellness/SelfCare.jpg'
    },
    {
      title: 'Cycle Syncing',
      url: '/explore/filter?categories=wellness_cycle-syncing&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/wellness/CycleSyncing.jpg'
    },
    {
      title: 'Nutrition',
      url: '/explore/filter?categories=wellness_nutrition&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/wellness/Nutrition.jpeg'
    },
    {
      title: 'Personal Growth',
      url: '/explore/filter?categories=wellness_personal-growth&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/wellness/Personal-Growth.jpg'
    }
  ]
}

export const skillsCategories = {
  category: 'Skills',
  seriesExploreLink: '/explore/filter?categories=skills&order=date',
  subcategories: [
    {
      title: 'Flexibility',
      url: '/explore/filter?categories=skills_flexibility&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/skills/HP-Img-Update_Skills__Flexibility.jpg'
    },
    {
      title: 'Inversions',
      url: '/explore/filter?categories=skills_inversions&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/skills/HP-Img-Update_Skills__Inversion.jpg'
    },
    {
      title: 'Mobility',
      url: '/explore/filter?categories=skills_mobility&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/skills/HP-Img-Update_Skills__Mobility.jpg'
    },
    {
      title: 'Backbends',
      url: '/explore/filter?categories=skills_backbends&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/skills/HP-Img-Update_Skills__Backbends.jpg'
    },
    {
      title: 'Arm Balances',
      url: '/explore/filter?categories=skills_arm-balances&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/skills/HP-Img-Update_Skills__Arm-Balances.jpg'
    },
    {
      title: 'How to Teach',
      url: '/explore/filter?categories=skills_how-to-teach&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/skills/HP-Img-Update_Skills__How-To-Teach.jpg'
    },
    {
      title: 'Yoga Poses',
      url: '/explore/filter?categories=skills_yoga-poses&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/skills/HP-Img-Update_Skills__Yoga-Poses.jpg'
    }
  ]
}
