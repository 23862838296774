export const yogaCategories = {
  category: 'Yoga',
  classFinderLink: '/class-finder?styles=yoga&order=date&',
  subcategories: [
    {
      title: 'Vinyasa',
      url: '/class-finder?styles=yoga_vinyasa&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Vinyasa.jpg'
    },
    {
      title: 'Hatha',
      url: '/class-finder?styles=yoga_hatha&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Hatha.jpg'
    },
    {
      title: 'Restorative',
      url: '/class-finder?styles=yoga_restorative&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Restorative.jpg'
    },
    {
      title: 'Ashtanga',
      url: '/class-finder?styles=yoga_ashtanga&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Ashtanga.jpg'
    },
    {
      title: 'Kundalini',
      url: '/class-finder?styles=yoga_kundalini&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Kundalini.jpg'
    },
    {
      title: 'Prenatal/Postnatal',
      url: '/class-finder?styles=yoga_prenatal-postnatal&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Prenatal.jpg'
    },

    {
      title: 'Kids Yoga',
      url: '/class-finder?styles=yoga_kids-yoga&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Kids-Yoga.jpg'
    }
  ]
}

export const fitnessCategories = {
  category: 'Fitness',
  classFinderLink: '/class-finder?styles=fitness&order=date&',
  subcategories: [
    {
      title: 'Pilates',
      url: '/class-finder?styles=fitness_pilates&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Pilates.jpg'
    },
    {
      title: 'Barre',
      url: '/class-finder?styles=fitness_barre&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Barre.jpg'
    },
    {
      title: 'Strength',
      url: '/class-finder?styles=fitness_strength&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Strength.jpg'
    },

    {
      title: 'Core',
      url: '/class-finder?styles=fitness_core&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Core.jpg'
    },

    {
      title: 'Sculpt',
      url: '/class-finder?styles=fitness_sculpt&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Sculpt.jpg'
    },
    {
      title: 'HIIT',
      url: '/class-finder?styles=fitness_hiit&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_HIIT.jpg'
    },
    {
      title: 'Stretching',
      url: '/class-finder?styles=fitness_stretching&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Stretching.jpg'
    },
    {
      title: 'Dance',
      url: '/class-finder?styles=fitness_dance&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Dance.jpg'
    },

    {
      title: 'Running',
      url: '/explore/filter?categories=fitness_running&order=date',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Running.jpg'
    },

    {
      title: 'Walking',
      url: '/class-finder?styles=fitness_walking&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Walking.jpg'
    },
    {
      title: 'Gym',
      url: '/class-finder?styles=fitness_gym&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/fitness/HP-Img-Update_Fitness_Gym.jpg'
    },
    {
      title: 'Prenatal/Postnatal',
      url: '/class-finder?styles=fitness_prenatal-postnatal&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/yoga/HP-Img-Update_Yoga_Prenatal.jpg'
    }
  ]
}

export const mindfulnessCategories = {
  category: 'Mindfulness',
  classFinderLink: '/class-finder?styles=mindfulness&order=date&',
  subcategories: [
    {
      title: 'Meditation',
      url: '/class-finder?styles=mindfulness_meditation&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/mindfulness/HP-Img-Update_Mindfulness_Meditation.jpg'
    },
    {
      title: 'Sound',
      url: '/class-finder?styles=mindfulness_sound&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/mindfulness/HP-Img-Update_Mindfulness_Sound.jpg'
    },
    {
      title: 'Breathwork',
      url: '/class-finder?styles=mindfulness_breathwork&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/mindfulness/HP-Img-Update_Mindfulness_Breathwork.jpg'
    },
    {
      title: 'Yoga Nidra',
      url: '/class-finder?styles=mindfulness_yoga-nidra&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/mindfulness/HP-Img-Update_Mindfulness_Yoga+Nidra.jpg'
    },
    {
      title: 'Reiki',
      url: '/class-finder?styles=mindfulness_reiki&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/mindfulness/HP-Img-Update_Mindfulness_Reiki.jpg'
    }
  ]
}

export const wellnessCategories = {
  category: 'Wellness',
  classFinderLink: '/class-finder?styles=wellness&order=date&',
  subcategories: [
    {
      title: 'Sleep',
      url: '/class-finder?styles=wellness_sleep&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/wellness/Sleep.jpg'
    },
    {
      title: 'Self-Care',
      url: '/class-finder?styles=wellness_self-care&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/wellness/SelfCare.jpg'
    },
    {
      title: 'Cycle Syncing',
      url: '/class-finder?styles=wellness_cycle-syncing&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/wellness/CycleSyncing.jpg'
    },
    {
      title: 'Nutrition',
      url: '/class-finder?styles=wellness_nutrition&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/wellness/Nutrition.jpeg'
    },
    {
      title: 'Personal Growth',
      url: '/class-finder?styles=wellness_personal-growth&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/wellness/Personal-Growth.jpg'
    }
  ]
}

export const skillsCategories = {
  category: 'Skills',
  classFinderLink: '/class-finder?styles=skills&order=date&',
  subcategories: [
    {
      title: 'Flexibility',
      url: '/class-finder?styles=skills_flexibility&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/skills/HP-Img-Update_Skills__Flexibility.jpg'
    },
    {
      title: 'Inversions',
      url: '/class-finder?styles=skills_inversions&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/skills/HP-Img-Update_Skills__Inversion.jpg'
    },
    {
      title: 'Mobility',
      url: '/class-finder?styles=skills_mobility&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/skills/HP-Img-Update_Skills__Mobility.jpg'
    },
    {
      title: 'Backbends',
      url: '/class-finder?styles=skills_backbends&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/skills/HP-Img-Update_Skills__Backbends.jpg'
    },
    {
      title: 'Arm Balances',
      url: '/class-finder?styles=skills_arm-balances&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/skills/HP-Img-Update_Skills__Arm-Balances.jpg'
    },
    {
      title: 'How to Teach',
      url: '/class-finder?styles=skills_how-to-teach&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/skills/HP-Img-Update_Skills__How-To-Teach.jpg'
    },
    {
      title: 'Yoga Poses',
      url: '/class-finder?styles=skills_yoga-poses&order=date&',
      image:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/navigation/categories/skills/HP-Img-Update_Skills__Yoga-Poses.jpg'
    }
  ]
}

export const durationClasses = {
  title: 'Duration',
  links: [
    {
      label: '0-15',
      url: '/class-finder?min=0&max=15&order=date'
    },
    {
      label: '15-30',
      url: '/class-finder?min=15&max=30&order=date'
    },
    {
      label: '30-60',
      url: '/class-finder?min=30&max=60&order=date'
    },
    {
      label: '60+',
      url: '/class-finder?min=60&order=date'
    }
  ]
}

export const difficultyClasses = {
  title: 'Difficulty',
  links: [
    {
      label: 'Introductory',
      url: '/class-finder?difficulties=introductory&order=date'
    },
    {
      label: 'Beginner',
      url: '/class-finder?difficulties=beginner&order=date'
    },
    {
      label: 'Moderate',
      url: '/class-finder?difficulties=moderate&order=date'
    },
    {
      label: 'Intermediate',
      url: '/class-finder?difficulties=intermediate&order=date'
    },
    {
      label: 'Advanced',
      url: '/class-finder?difficulties=advanced&order=date'
    }
  ]
}
