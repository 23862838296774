import React from 'react'
import { Flex, P } from 'ui/acro'

const SeeAllCategoryLink = ({ title, href }) => {
  return (
    <Flex
      as='a'
      href={href}
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <P
        textAlign='center'
        width='max-content'
        color='white'
        style={{ textDecoration: 'underline' }}
      >
        Browse All
        <br></br>
        {title}
      </P>
    </Flex>
  )
}

export default SeeAllCategoryLink
