import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { MyPractice } from 'images/latest'

const MyPracticeLink = ({ color = 'white' }) => (
  <ResponsiveSVG
    width={['24px', null, '24px']}
    className='unstyled'
    fill={color}
    as='a'
    id='navbar-practice-link'
    href='/my/practice'
    aria-label='Navigate to your practice'
    left={true}
    right={true}
    SVG={MyPractice}
  />
)

export default MyPracticeLink
