import React from 'react'

import { Caption } from 'ui/acro'

const MobileMenuLink = ({ open, ...props }) => (
  <Caption
    variant='medium'
    fontSize='18px'
    as='a'
    pt='15px'
    pb='14px'
    px='4px' // Inset
    color='white'
    className='unstyled'
    fontWeight='400'
    tabIndex={open ? '0' : '-1'}
    {...props}
  />
)

export default MobileMenuLink
