import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { Profile } from 'images/latest'

const ProfileIcon = ({ color = 'white' }) => (
  <ResponsiveSVG
    width={['24px', null, '24px']}
    className='unstyled'
    fill={color}
    SVG={Profile}
  />
)

export default ProfileIcon
