import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { Shop } from 'images/latest'

const ShopIcon = ({ color = 'white', height = '20px' }) => (
  <ResponsiveSVG
    width={['20px', null, '20px']}
    height={height}
    className='unstyled'
    fill={color}
    svgProps={{ viewBox: '0 0 20 20' }}
    SVG={Shop}
  />
)

export default ShopIcon
