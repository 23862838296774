import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useUserContext } from 'context'
import { Flex, Responsive, Box } from 'ui/acro'
import NavbarLink from '../../common/NavbarLink'
import Profile from './Profile/Profile'
import MyPracticeLink from './MyPractice/MyPracticeLink'
import styled, { css } from 'styled-components'
import Search from './Search/DesktopSearch'

const ContentContainer = styled(Flex)((props) =>
  css({
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexGrow: 0
  })
)

const RightLinks = styled(Flex)({
  gap: '35px',
  alignItems: 'end',
  '&:nth-child(1)': {
    paddingLeft: '25px'
  }
})

const RightContent = ({ links, auth }) => {
  const { user } = useUserContext()

  const authLink = user ? (
    <NavbarLink href='/signout' id='nav_signout_web' className='unstyled'>
      Sign Out
    </NavbarLink>
  ) : (
    <NavbarLink href='/signin' id='nav_signin_web' className='unstyled'>
      Sign In
    </NavbarLink>
  )

  return (
    <ContentContainer>
      {links && (
        <Fragment>
          {/* Desktop menu */}
          <Responsive
            show={[false, false, false, true]}
            justifyContent='inherit'
            alignItems='inherit'
            flex='inherit'
          >
            {user ? (
              // Signed in
              <RightLinks>
                <Box>
                  <Search />
                </Box>
                <Box>
                  <MyPracticeLink color='black' />
                </Box>
                <Box>
                  <Profile color='black' />
                </Box>
              </RightLinks>
            ) : (
              // Signed out
              <Box ml='5em'>{authLink}</Box>
            )}
          </Responsive>
        </Fragment>
      )}

      {/* Sometimes we show just the sign in/out link */}
      {!links && auth && authLink}
    </ContentContainer>
  )
}

RightContent.propTypes = {
  links: PropTypes.bool,
  auth: PropTypes.bool,
  menuIsOpen: PropTypes.bool,
  toggleMenu: PropTypes.func
}

export default RightContent
