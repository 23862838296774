import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { axios } from 'api'
import { useUserContext } from 'context'

const Store = React.createContext(null)
export const useNavbarContext = () => useContext(Store)

export const NavbarStoreProvider = ({ children }) => {
  const { user } = useUserContext()
  const [notificationCount, setNotificationCount] = useState(0)
  const [shopMenu, setShopMenu] = useState([])
  const [shopFlagsPresent, setShopFlagsPresent] = useState(false)

  // Get notification count for current user
  useEffect(() => {
    const getNotifications = async () => {
      try {
        const res = await axios.get('/notifications/unread_count')
        if (res && res.data) {
          setNotificationCount(res.data.count)
        }
      } catch (e) {
        return
      }
    }

    if (user) getNotifications()
  }, [user])

  // Get shop menu items (Gear, Clothing, etc.)
  useEffect(() => {
    const getShopMenu = async () => {
      try {
        const res = await axios.get(
          `/shopping/menu?client=web&signed_in=${Boolean(user)}`
        )
        if (res && res.data) {
          setShopMenu(res.data)
          setShopFlagsPresent(res.data.some((item) => Boolean(item.flag)))
        }
      } catch (e) {
        // Fail silently
        return
      }
    }

    getShopMenu()
  }, [user])

  const clearNotifications = async (e) => {
    e.preventDefault()

    try {
      await axios.delete('/notifications/unread_count')
    } catch (e) {
      // Fail silently
    } finally {
      window.location.href = '/notifications'
    }
  }

  const state = {
    notificationCount,
    shopMenu,
    shopFlagsPresent
  }

  const actions = {
    clearNotifications
  }

  return <Store.Provider value={{ state, actions }}>{children}</Store.Provider>
}

NavbarStoreProvider.propTypes = {
  children: PropTypes.node.isRequired
}
