import * as Yup from 'yup'
import { axios } from 'api'

import {
  setPlans,
  setPromoCode,
  setSelectedPlan,
  setSuccessfulSubmit
} from '../actions'

// We export findPlan so it can be used in the reducer.
export const findPlan = (plans, id) => plans.find((plan) => plan.id == id)
export const findMWEPlan = (plans, mweCurrentFrequency) =>
  plans.find((plan) => plan.frequency == mweCurrentFrequency)

const initializeMembership = async (
  dispatch,
  {
    forUpdate = false,
    successfulSubmit = false,
    getPlansURL = '/membership_plans',
    handlePromoCodeFail = () => {},
    promoCode = '',
    selectedPlanId = 'yearly',
    isMwe = false,
    mweCurrentFrequency = 'yearly'
  }
) => {
  let plans, defaultPlans, selectedPlan, selectedPlanError, loadError, mweData

  const getPlans = async (params) => {
    const res = await axios.get(getPlansURL, { params })

    if (res.status === 204) {
      return []
    }

    if (res && res.data) {
      return res.data
    }
  }

  // Get the default Plans
  try {
    if (isMwe) {
      const res = await getPlans()
      if (res) {
        mweData = res
        defaultPlans = res.plans
      }
    } else {
      const res = await getPlans({ for_update: forUpdate })
      if (res) {
        defaultPlans = res
      }
    }
  } catch (error) {
    if (!error.message) {
      throw Error('There was a problem retrieving default plans')
    } else {
      throw error
    }
  }

  // If there is a promoCode passed through, check for the promoCode plans.
  // If there is a promoCode error, we run passed through callback
  if (promoCode) {
    try {
      const promoPlans = await getPlans({
        for_update: forUpdate,
        promo_code: promoCode,
        verify: true
      })
      if (promoPlans) {
        plans = promoPlans
      }
    } catch (error) {
      if (error && error.response && error.response.status === 412) {
        if (typeof handlePromoCodeFail === 'function') {
          handlePromoCodeFail()
        } else {
          throw Error(
            'Please provide a valid function for handlePromoCodeFail()'
          )
        }
        promoCode = ''
      }
    }
  }

  // If the promoCode didn't load any plans, we need to set the default plans now.
  if (!plans) {
    plans = defaultPlans
  }

  // Once plans are set, either default or promo, we then retrieve our selectedPlan object.
  // Only run if selectedPlanId is set (pass null to have no plan pre-selected).
  if (isMwe) {
    selectedPlan = findMWEPlan(plans, mweCurrentFrequency)
  } else if (plans && selectedPlanId) {
    selectedPlan = findPlan(plans, selectedPlanId)
    if (promoCode && !selectedPlan.couponApplies) {
      // alert user if there's a discrepency between the promo code and selected plan.
      selectedPlanError = 'This promo code does not apply to the selected plan.'
    }
  }

  // Default to the first plan if we can't find the plan that we think should be selected.
  if (!selectedPlan) {
    selectedPlan = plans[0]
  }

  // We use state and values so we can easily distinguish between the two versions:
  // state === a promoCode that has been verified and applied
  // values === an ephemeral promoCode container for entering a promoCode within the form.
  return {
    type: 'membership',
    loadError,
    state: {
      defaultPlans,
      plans,
      selectedPlan,
      selectedPlanError,
      promoCode,
      forUpdate,
      successfulSubmit,
      mweData,
      isMwe
    },
    values: {
      selectedPlan: selectedPlan && selectedPlan.id,
      selectedPlanSet: false,
      promoCodeSet: Boolean(promoCode),
      promoCode
    },
    validators: {
      selectedPlan: Yup.string().required(),
      selectedPlanSet: Yup.boolean(),
      promoCodeSet: Yup.boolean(),
      promoCode: Yup.string().when('promoCodeSet', {
        is: true,
        then: Yup.string(),
        otherwise: Yup.string().max(0, 'This promo code cannot be applied.')
      })
    },
    actions: {
      findPlan,
      selectPlan: (plan) => dispatch(setSelectedPlan(plan)),
      refreshPlans: async () => {
        // will refresh the plans available to a user.
        try {
          const plans = await getPlans({ for_update: forUpdate })
          if (plans) {
            dispatch(setPlans(plans))
            return plans
          }
        } catch (e) {
          // There are no plans available for update.
          dispatch(setPlans())
        }
      },
      setPromoCode: async (promoCode) => {
        // Check if a promoCode applies to any plans. If so, set the promoCode and return the plans.
        // If setPromoCode() is given no promoCode, it will fetch default plans and clear any state.membership.promoCode.
        // If there is any error with getting plans, it will clear out promoCode state, reset plans to default, and bubble an error to the action caller.
        try {
          const plans = await getPlans({
            for_update: forUpdate,
            promo_code: promoCode,
            verify: promoCode && true
          })

          if (plans) {
            dispatch(setPromoCode({ promoCode, plans }))
            return plans
          }
        } catch (e) {
          // We were unable to apply the promo code (usually because of 412, invalid code).
          // Set state promo code to blank and throw an error.
          dispatch(setPromoCode({ promoCode: '' }))
          throw Error('This promo code cannot be applied.')
        }
      },
      handleSubmit: async ({ state, checkout = false, values }) => {
        try {
          let res
          if (forUpdate) {
            res = await axios.put(`/users/me/membership/stripe`, {
              plan_id: values.selectedPlan,
              checkout,
              cancel_path: window.location.pathname
            })
          } else {
            res = await axios.post(`/users/me/membership/stripe`, {
              coupon_code: state.membership.promoCode,
              plan: values.selectedPlan,
              idempotency_key: state.idempotencyKey,
              checkout,
              cancel_path: window.location.pathname
            })
          }
          if (res) {
            dispatch(setSuccessfulSubmit(true))
            return res
          }
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            let message = error.response.data.error
            if (!message.includes('support@alomoves.com')) {
              message += ' Please contact support@alomoves.com for assistance.'
            }
            throw Error(message)
          } else {
            throw Error(
              'There was an unknown error processing your request. Please contact support@alomoves.com'
            )
          }
        }
      }
    }
  }
}

export default initializeMembership
