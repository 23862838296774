import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Box, Flex, Image, NameList } from 'ui/acro'
import TimeCodeBadge from './TimeCodeBadge'
import ClassCountBadge from './ClassCountBadge'
import { CardSubtitle, CardTitle } from '../styles'

// this card ingests both series and class API data from the my_classes_and_series API and displays them accordingly. it's made specifically for the keep training carousel
const KeepTrainingCard = forwardRef(
  (
    {
      carouselItem,
      title,
      id,
      humanDuration,
      plan,
      category,
      subcategory,
      videoThumbnail,
      introVideoThumbnail,
      workoutCount,
      coaches,
      primaryCategory,
      primarySubcategory,
      slug
    },
    ref
  ) => {
    const image = videoThumbnail || introVideoThumbnail

    return (
      <Flex
        ref={ref}
        id={id}
        flexDirection='column'
        position='relative'
        className={carouselItem ? 'swiper-slide unstyled' : 'unstyled'}
        style={{ minWidth: '198px' }}
        as='a'
        href={plan ? `/series/${plan.slug}/workouts/${id}` : `/series/${slug}`}
      >
        {image && (
          <Box position='relative' mb={['8px', '10px']}>
            <Image
              src={image}
              objectFit='cover'
              position='absolute'
              top='0'
              left='0'
            />
            {humanDuration ? (
              <TimeCodeBadge
                fontSize='13px !important'
                bottom='16px !important'
                left='16px !important'
              >
                {humanDuration}
              </TimeCodeBadge>
            ) : (
              <ClassCountBadge
                fontSize='13px !important'
                bottom='16px !important'
                left='16px !important'
              >
                {workoutCount} classes
              </ClassCountBadge>
            )}
          </Box>
        )}
        {title && (
          <CardTitle id='SeriesTitle' as='div' paddingBottom='6px'>
            {title}
          </CardTitle>
        )}
        {plan ? (
          <CardSubtitle as='div'>
            <NameList people={plan.coaches} />
          </CardSubtitle>
        ) : (
          <CardSubtitle as='div'>
            <NameList people={coaches} />
          </CardSubtitle>
        )}
        {category ? (
          <CardSubtitle as='div'>
            {category.title && category.title}
            {subcategory && subcategory.title && ` / ${subcategory.title}`}
          </CardSubtitle>
        ) : (
          <CardSubtitle as='div'>
            {primaryCategory && primaryCategory.title}
            {primarySubcategory && ` / ${primarySubcategory.title}`}
          </CardSubtitle>
        )}
      </Flex>
    )
  }
)

KeepTrainingCard.propTypes = {
  carouselItem: PropTypes.bool,
  title: PropTypes.string,
  id: PropTypes.number,
  planEntryUrl: PropTypes.string,
  humanDuration: PropTypes.any,
  plan: PropTypes.object,
  category: PropTypes.object,
  subcategory: PropTypes.object,
  videoThumbnail: PropTypes.string,
  introVideoThumbnail: PropTypes.string,
  workoutCount: PropTypes.number,
  coaches: PropTypes.array,
  primaryCategory: PropTypes.object,
  primarySubcategory: PropTypes.object,
  slug: PropTypes.string
}

export default KeepTrainingCard
