import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { Series } from 'images/latest'

const SeriesIcon = ({
  color,
  width = ['24px', null, '24px'],
  height = ['24px', null, '24px']
}) => (
  <ResponsiveSVG
    width={width}
    height={height}
    className='unstyled'
    color={color}
    SVG={Series}
  />
)

export default SeriesIcon
