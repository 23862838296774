import css from '@styled-system/css'
import DropdownMenu from 'components_v2/Header/Navbar/Menus/common/DropdownMenu'
import ClassResultItem from 'components_v2/Header/Navbar/Menus/Desktop/RightContent/Search/SearchDrawer/common/ClassResultItem'
import { useFetchAPI } from 'hooks'
import {
  Introductory,
  Advanced,
  Beginner,
  Intermediate,
  Moderate
} from 'images'
import React from 'react'
import styled from 'styled-components'
import { Flex, Grid, P, ResponsiveSVG, UL, LI } from 'ui/acro'
import {
  difficultyClasses,
  durationClasses,
  fitnessCategories,
  mindfulnessCategories,
  skillsCategories,
  wellnessCategories,
  yogaCategories
} from '../../../../common/data/ClassNavData'

const ClassDropdown = styled(DropdownMenu)(() =>
  css({
    position: 'absolute',
    width: '100%',
    top: '4.35em',
    left: '0'
  })
)
const ClassGrid = styled(Grid)(() =>
  css({
    maxWidth: '1200px',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridColumnGap: '1.5em',
    pb: '40px',
    '.long-grid-item': {
      gap: '8px',
      overflow: 'hidden'
    },
    '.full-width-grid-item': {
      gridColumn: '1 / -1',
      gap: '10px'
    }
  })
)
const FlexBox = styled(UL)(() =>
  css({
    display: 'flex',
    flexDirection: 'column',
    gap: '5px'
  })
)
const SectionTitle = styled(P)(() =>
  css({
    fontFamily: 'Arquitecta, Helvetica, Arial, sans-serif',
    fontSize: '16px',
    fontWeight: 500,
    textTransform: 'uppercase',
    letterSpacing: '.05em',
    borderBottom: '1px solid #E1E1E1',
    mb: '10px',
    pb: '3px'
  })
)
const SectionTitleLink = styled(SectionTitle)(() =>
  css({
    '&:hover': {
      textDecoration: [null, null, null, 'underline']
    }
  })
)
const SectionItem = styled(P)(() =>
  css({
    fontSize: '16px',
    letterSpacing: '.05em',
    margin: '0',
    '&:hover': {
      textDecoration: [null, null, null, 'underline']
    }
  })
)
const DurationButton = styled(Flex)(() =>
  css({
    fontFamily: 'Arquitecta, Helvetica, Arial, sans-serif',
    fontSize: '16px',
    fontWeight: 400,
    textTransform: 'uppercase',
    letterSpacing: '.05em',
    border: '1px solid #CCCCCC',
    borderRadius: '50%',
    padding: '4px',
    minWidth: '70px',
    aspectRatio: '1/1',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flexWrap: 'wrap',
    textAlign: 'center',
    transition: '.5s ease',
    '.small-text': {
      fontSize: '10px'
    },
    '&:hover': {
      backgroundColor: [null, null, null, '#F2F2F2'],
      border: [null, null, null, '1px solid #F2F2F2']
    }
  })
)
const DistanceButton = styled(Flex)(() =>
  css({
    fontFamily: 'Arquitecta, Helvetica, Arial, sans-serif',
    fontSize: '16px',
    fontWeight: 400,
    textTransform: 'uppercase',
    letterSpacing: '.05em',
    border: '1px solid #CCCCCC',
    borderRadius: '53px',
    padding: '5px 15px',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    textAlign: 'center',
    transition: '.5s ease',
    '.small-text': {
      fontSize: '10px'
    },
    '&:hover': {
      backgroundColor: [null, null, null, '#F2F2F2'],
      border: [null, null, null, '1px solid #F2F2F2']
    }
  })
)

const BtnContainer = styled(Flex)(() =>
  css({
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingBottom: '0.8em',
    gap: '1em 1.5em'
  })
)

const ClassDropdownMenu = () => {
  const [{ data: classesVideos }] = useFetchAPI(
    '/suggest/created/categories/recent/?thumbnails=normal&limit=3'
  )
  const newClasses = classesVideos && classesVideos.content

  return (
    <ClassDropdown width='100%'>
      <ClassGrid>
        <FlexBox>
          <SectionTitleLink
            id={`DesktopClassMenu${yogaCategories.category}`}
            as='a'
            href={yogaCategories.classFinderLink}
            className='unstyled'
          >
            {yogaCategories.category}
          </SectionTitleLink>
          {yogaCategories.subcategories.map((item, idx) => {
            return (
              <LI key={idx}>
                <SectionItem
                  id={`DesktopClassMenu${item.title.replace(/ /g, '')}`}
                  as='a'
                  className='unstyled'
                  href={item.url}
                >
                  {item.title}
                </SectionItem>
              </LI>
            )
          })}
        </FlexBox>
        <FlexBox>
          <SectionTitleLink
            id={`DesktopClassMenu${fitnessCategories.category}`}
            as='a'
            href={fitnessCategories.classFinderLink}
            className='unstyled'
          >
            {fitnessCategories.category}
          </SectionTitleLink>
          {fitnessCategories.subcategories.map((item, idx) => {
            return (
              <LI key={idx}>
                <SectionItem
                  id={`DesktopClassMenu${item.title.replace(/ /g, '')}`}
                  as='a'
                  className='unstyled'
                  href={item.url}
                >
                  {item.title}
                </SectionItem>
              </LI>
            )
          })}
        </FlexBox>
        <FlexBox>
          <SectionTitleLink
            id={`DesktopClassMenu${mindfulnessCategories.category}`}
            as='a'
            href={mindfulnessCategories.classFinderLink}
            className='unstyled'
          >
            {mindfulnessCategories.category}
          </SectionTitleLink>
          {mindfulnessCategories.subcategories.map((item, idx) => {
            return (
              <LI key={idx}>
                <SectionItem
                  id={`DesktopClassMenu${item.title.replace(/ /g, '')}`}
                  as='a'
                  className='unstyled'
                  href={item.url}
                >
                  {item.title}
                </SectionItem>
              </LI>
            )
          })}
        </FlexBox>
        <FlexBox>
          <SectionTitleLink
            id={`DesktopClassMenu${wellnessCategories.category}`}
            as='a'
            href={wellnessCategories.classFinderLink}
            className='unstyled'
          >
            {wellnessCategories.category}
          </SectionTitleLink>
          {wellnessCategories.subcategories.map((item, idx) => {
            return (
              <LI key={idx}>
                <SectionItem
                  id={`DesktopClassMenu${item.title.replace(/ /g, '')}`}
                  as='a'
                  className='unstyled'
                  href={item.url}
                >
                  {item.title}
                </SectionItem>
              </LI>
            )
          })}
        </FlexBox>
        <FlexBox>
          <SectionTitleLink
            id={`DesktopClassMenu${skillsCategories.category}`}
            as='a'
            href={skillsCategories.classFinderLink}
            className='unstyled'
          >
            {skillsCategories.category}
          </SectionTitleLink>
          {skillsCategories.subcategories.map((item, idx) => {
            return (
              <LI key={idx}>
                <SectionItem
                  id={`DesktopClassMenu${item.title.replace(/ /g, '')}`}
                  as='a'
                  className='unstyled'
                  href={item.url}
                >
                  {item.title}
                </SectionItem>
              </LI>
            )
          })}
        </FlexBox>
        <FlexBox className='long-grid-item'>
          <SectionTitle>{durationClasses.title}</SectionTitle>
          <BtnContainer>
            {durationClasses.links.map((item, idx) => {
              return (
                <DurationButton
                  id={`DesktopClassMenuDuration${item.label}`}
                  key={idx}
                  as='a'
                  className='unstyled'
                  href={item.url}
                >
                  {item.label}
                  <br></br>
                  <span className='small-text'>MIN</span>
                </DurationButton>
              )
            })}
          </BtnContainer>
          <SectionTitle>{difficultyClasses.title}</SectionTitle>
          <Flex flexWrap='wrap' style={{ gap: '15px' }} padding='10px 0'>
            {difficultyClasses.links.map((item, idx) => {
              const icons = {
                Introductory: Introductory,
                Beginner: Beginner,
                Moderate: Moderate,
                Intermediate: Intermediate,
                Advanced: Advanced
              }
              const icon = icons[item.label] ? icons[item.label] : () => null
              return (
                <DistanceButton
                  id={`DesktopClassMenuDifficulty${item.label}`}
                  key={idx}
                  as='a'
                  className='unstyled'
                  href={item.url}
                >
                  <ResponsiveSVG SVG={icon} fill='black' width='15px' />
                  <P
                    fontFamily='Arquitecta'
                    fontSize='16px'
                    letterSpacing='.05em'
                    margin='0'
                  >
                    {item.label}
                  </P>
                </DistanceButton>
              )
            })}
          </Flex>
        </FlexBox>
        {newClasses && newClasses.length > 0 && (
          <FlexBox className='full-width-grid-item'>
            <SectionTitleLink
              id={'DesktopClassMenuNewReleases'}
              as='a'
              href={'/class-finder?order=date&'}
              className='unstyled'
            >
              New Releases
            </SectionTitleLink>
            <Flex flexDirection='row' justifyContent='space-between'>
              {newClasses.map((item, idx) => {
                return (
                  <LI id={'DesktopClassNewRelease' + (idx + 1)} key={idx}>
                    <ClassResultItem
                      href={item.url && item.url}
                      thumbnailImage={
                        item.thumbnails.default && item.thumbnails.default
                      }
                      title={item.title && item.title}
                      humanDuration={item.duration && item.duration}
                      coaches={item.instructors && item.instructors}
                      category={
                        item.primaryCategory.name && item.primaryCategory.name
                      }
                      subcategory={
                        item.primarySubcategory.name &&
                        item.primarySubcategory.name
                      }
                    />
                  </LI>
                )
              })}
            </Flex>
          </FlexBox>
        )}
      </ClassGrid>
    </ClassDropdown>
  )
}

export default ClassDropdownMenu
