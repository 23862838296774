import React from 'react'
import { ResponsiveSVG } from 'ui/acro'
import { Explore } from 'images/latest'

const ExploreIcon = ({ color = 'white', height = '28px' }) => (
  <ResponsiveSVG
    width={['24px', null, '24px']}
    height={height}
    className='unstyled'
    svgProps={{ viewBox: '0 0 28 28', stroke: color }}
    SVG={Explore}
  />
)

export default ExploreIcon
