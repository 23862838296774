import css from '@styled-system/css'
import React from 'react'
import styled from 'styled-components'
import { Box, P } from 'ui/acro'

const SeriesBanner = styled(Box)`
  ${css({
    width: '100%',
    padding: ['10px 20px', '15px', '15px', '19px'],
    mt: [null, null, null, '2px'],
    mb: ['10px', '10px', '10px', null],
    backgroundColor: '#384669'
  })}
`

const SeriesInfoBanner = () => {
  return (
    <SeriesBanner mt={[null, null, null, '2px']}>
      <P
        color='white'
        textAlign='center'
        fontSize={['14px', '16px']}
        fontWeight='600'
        margin='0'
      >
        Follow a program by an expert instructor to meet all your fitness and
        wellness goals.
      </P>
    </SeriesBanner>
  )
}

export default SeriesInfoBanner
