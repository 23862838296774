import React, { Fragment } from 'react'
import { useUserContext } from 'context'
import { useFetchAPI } from 'hooks'
import { Box, Flex, Image } from 'ui/acro'
import {
  fitnessCategories,
  mindfulnessCategories,
  skillsCategories,
  yogaCategories,
  wellnessCategories
} from '../../common/data/SeriesNavData'
import SeriesInfoBanner from '../../common/SeriesInfoBanner'
import { SeriesResultItem } from '../../Desktop/RightContent/Search/SearchDrawer/common'
import { FullDivider } from '../common/Dividers'
import MobileMenuAccordion, {
  AccordionContent,
  AccordionP
} from '../common/MobileMenuAccordion'
import SeeAllCategoryLink from '../common/SeeAllCategoryLink'

const MobileMenuSeries = () => {
  const { user } = useUserContext()
  const classCategories = [
    {
      category: 'Yoga',
      data: yogaCategories.subcategories,
      seriesExploreLink: yogaCategories.seriesExploreLink
    },
    {
      category: 'Fitness',
      data: fitnessCategories.subcategories,
      seriesExploreLink: fitnessCategories.seriesExploreLink
    },
    {
      category: 'Mindfulness',
      data: mindfulnessCategories.subcategories,
      seriesExploreLink: mindfulnessCategories.seriesExploreLink
    },
    {
      category: 'Wellness',
      data: wellnessCategories.subcategories,
      classFinderLink: wellnessCategories.seriesExploreLink
    },
    {
      category: 'Skills',
      data: skillsCategories.subcategories,
      seriesExploreLink: skillsCategories.seriesExploreLink
    }
  ]

  const [{ data: seriesVideos }] = useFetchAPI('/newest_plans.json?limit=2')
  const newSeries = seriesVideos && seriesVideos.content

  return (
    <Box pb='env(safe-area-inset-bottom)'>
      <SeriesInfoBanner />
      {classCategories.map((item, idx) => {
        return (
          <Fragment key={idx}>
            <MobileMenuAccordion title={item.category}>
              <AccordionContent>
                <>
                  {item.data.map((item, idx) => {
                    return (
                      <Flex
                        key={idx}
                        as='a'
                        href={item.url}
                        flexDirection='column'
                      >
                        <Image src={item.image} minWidth='130px' />
                        <AccordionP mt='8px'>{item.title}</AccordionP>
                      </Flex>
                    )
                  })}
                  <SeeAllCategoryLink
                    title={item.category}
                    href={item.seriesExploreLink}
                  />
                </>
              </AccordionContent>
            </MobileMenuAccordion>
            <FullDivider />
          </Fragment>
        )
      })}
      {/* the API we use to render new releases cards isn't available for signed out users */}
      {user && newSeries && (
        <>
          <MobileMenuAccordion title={'New Releases'}>
            <AccordionContent flexDirection='column' mb='25px'>
              <>
                {newSeries.map((item, idx) => {
                  return (
                    <SeriesResultItem
                      key={idx}
                      textColor={'white !important'}
                      id={'NewReleaseSeries' + (idx + 1)}
                      href={item.url && item.url}
                      thumbnailImage={
                        item.thumbnails.default && item.thumbnails.default
                      }
                      classesCount={item.classCount && item.classCount}
                      title={item.title && item.title}
                      coaches={item.instructors && item.instructors}
                      category={
                        item.primaryCategory.name && item.primaryCategory.name
                      }
                      subcategory={
                        item.primarySubcategory.name &&
                        item.primarySubcategory.name
                      }
                    />
                  )
                })}
                <SeeAllCategoryLink
                  title={'New Releases'}
                  href={'/explore/filter?order=date'}
                />
              </>
            </AccordionContent>
          </MobileMenuAccordion>
          <FullDivider />
        </>
      )}
    </Box>
  )
}

export default MobileMenuSeries
