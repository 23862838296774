import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import css from '@styled-system/css'
import { CSSTransition } from 'react-transition-group'
import { Flex, Close, componentHeights as heights, Box } from 'ui/acro'
import Logo from '../../Logo'
import StyledMobileMenu from './common/StyledMobileMenu'
import { LeftArrowIcon } from 'components_v2/Icons'
import MobileMenuPrimary from './MobileMenuPages/MobileMenuPrimary'
import MobileMenuClasses from './MobileMenuPages/MobileMenuClasses'
import MobileMenuSeries from './MobileMenuPages/MobileMenuSeries'

const MobileMenuHeightTransition = styled(CSSTransition)(() =>
  css({
    '&.mobile-menu-enter-active': {
      height: '0'
    },
    '&.mobile-menu-enter-done': {
      height: '100%',
      transition: '.3s'
    },
    '&.mobile-menu-exit': {
      height: '100%'
    },
    '&.mobile-menu-exit-active': {
      height: '0',
      transition: '.3s'
    }
  })
)
const FlexTopBar = styled(Flex)`
  ${css({
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'sticky',
    top: '0',
    background: 'black',
    zIndex: '10002',
    mb: '16px'
  })}
`

const MobileMenu = ({ open, onToggle }) => {
  const headerHeight = [heights.navbar.mobile, null, heights.navbar.desktop]
  const [page, setPage] = useState('Home')

  const closeMobileMenu = () => {
    onToggle()
    setPage('Home')
  }

  const pageSelected = (value) => {
    setPage(value)
  }

  const page2 = page === 'Classes' || page === 'Series'

  return (
    <MobileMenuHeightTransition
      in={open}
      timeout={300}
      mountOnEnter
      unmountOnExit
      classNames='mobile-menu'
    >
      <StyledMobileMenu aria-hidden={open ? 'false' : 'true'}>
        <FlexTopBar height={headerHeight} minHeight={headerHeight}>
          {/* show back arrow on page 2, and on page 1 keep the flex items the same with empty div */}
          {page2 ? (
            <Flex onClick={() => setPage('Home')} margin='5px'>
              <LeftArrowIcon color='white' width='25px' />
            </Flex>
          ) : (
            <Box width='35px' />
          )}
          <Flex alignItems='center' justifyContent='center' flexGrow={1}>
            <Logo color='white' />
          </Flex>
          <Close
            onClick={closeMobileMenu}
            id='menu_close_mobile'
            fill='white'
          />
        </FlexTopBar>
        {(() => {
          switch (page) {
            case 'Home':
              return <MobileMenuPrimary pageSelected={pageSelected} />
            case 'Classes':
              return <MobileMenuClasses />
            case 'Series':
              return <MobileMenuSeries />
            default:
              return <MobileMenuPrimary pageSelected={pageSelected} />
          }
        })()}
      </StyledMobileMenu>
    </MobileMenuHeightTransition>
  )
}

MobileMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired
}

export default MobileMenu
